/**
* Generated automatically at built-time (2024-11-05T10:46:39.891Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "ideen-outdoorkueche",templateKey: "sites/108-eac7f681-ecc4-49ea-89d2-dd0ce4d8ab9d"};