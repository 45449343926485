/**
* Generated automatically at built-time (2024-11-05T10:46:39.829Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-stainless-collection-outdoorkueche",templateKey: "sites/108-fa7be410-ba51-478a-b40a-66b44a9b3ca5"};